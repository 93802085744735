import React from 'react'
import bgImg from '@/shared/assets/bg_stars.png'

import { ReactComponent as BgLines } from '@/shared/assets/lines.svg'
import { ReactComponent as Planet } from '@/shared/assets/planetbg.svg'
import { ReactComponent as Galaxy } from '@/shared/assets/galaxy.svg'

import { BecomePart } from '@/widgets/BecomePart/BecomePart'
import { GetFun } from '@/widgets/GetFun/GetFun'
import { AmazingGraphics } from '@/widgets/AmazingGrapics/AmazingGraphics'
import { EasyAdministration } from '@/widgets/EasyAdministration/EasyAdministration'
import { Ending } from '@/widgets/Ending/Ending'
import { Header } from '@/widgets/Header/Header'
import useResize from '@/shared/lib/hooks/useResize'
import { MobileMain } from '@/Mobile/MobileMain/MobileMain'
import { Footer } from '@/widgets/Footer/Footer'

import cls from './main.module.scss'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import { sendMetrik } from '@/shared/lib/utils/sendMetrik'

export const Main = () => {
    const windowWidth = useResize()
    return windowWidth > 912 ? (
        <div className={cls.wrapper}>
            <main className={cls.Main}>
                <Header />
                <Outlet />
                <Footer />
            </main>
        </div>
    ) : (
        <MobileMain>
            <Outlet />
        </MobileMain>
    )
}
