import React from "react"
import logo from "@/shared/assets/logo_stellar_bet.webp"

import cls from "./footer.module.scss"
import AnchorLink from "react-anchor-link-smooth-scroll"
import { useLocation, useNavigate } from "react-router-dom"
import {scrollIntoView} from "@/shared/lib/utils/scrollIntoView";
import {sendMetrik} from "@/shared/lib/utils/sendMetrik";
export const Footer = () => {
    const location = useLocation()
    const navigate = useNavigate()

    const navigationItem = (content, href, offset) => {
        if (location.pathname !== "/") {
            return (
                <span
                    style={{ cursor: "pointer" }}
                    onClick={() => navigate("/")}>
                    {content}
                </span>
            )
        } else {
            return (
                <AnchorLink
                    href={href}
                    offset={() => offset}>
                    {content}
                </AnchorLink>
            )
        }
    }
    return (
        <div className={cls.footerWrapper}>
            <footer className={cls.Footer}>
                <div className={cls.topblock}>
                    <img alt="some problems"
                        src={logo}
                        className={cls.logo}
                         onClick={() => {window.location.href="https://stellar-softs.com"}}
                    />
                    <nav>
                        <p
                            onClick={() =>
                                scrollIntoView('distributor')
                            }>
                            Become a distributor
                        </p>
                        <a
                            href="https://stellar-softs.com"
                            onClick={() => {
                                sendMetrik('click', 'header_main_page_click')
                            }}
                        >
                            Home
                        </a>
                        <p onClick={() => scrollIntoView('features')}>Features</p>
                        <p onClick={() => scrollIntoView('contacts')}>Contacts</p>
                    </nav>
                </div>
                <div className={cls.copyright}>
                    <p>© 2024 Stellar Bets</p>
                    <p>All rights reserved.</p>
                </div>
            </footer>
        </div>
    )
}
